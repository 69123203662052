.view {
  margin: 0 auto;
  margin-left: auto;
  padding: 1rem;
}

.header {
  border-bottom: solid 1px var(--border-color);
  gap: 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  padding: 1rem 0;
}

.loading {
  border: 3px solid var(--upper-gradient);
  border-radius: 50%;
  border-top-color: var(--lower-gradient);
  animation: spin 1s ease-in-out infinite;
  -webkit-animation: spin 1s ease-in-out infinite;
  margin: auto;
  margin-top: 10rem;
}

.banner {
  width: 100%;
  height: 5em;
  background-image: linear-gradient(
    to right,
    var(--upper-gradient),
    var(--lower-gradient)
  );
}

.no-files {
  display: flex;
  justify-content: center;
  align-items: center;
}

.no-files-text {
  margin: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.header {
  border-bottom: solid 1px var(--border-color);
  gap: 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  padding: 2rem;
  padding-bottom: 1rem;
}

.files-container {
  width: 100%;
  max-height: 360px;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  gap: 5px;
  padding: 5px;
}

.files-list {
  display: flex;
  flex-direction: row;
  padding: 1rem;
}

.files-list-checkbox-group {
  width: 100%;
}

.file-row {
  background-color: var(--row-color);
  width: 100%;
  border-radius: 2px;
  border: 1px solid;
  border-color: var(--row-border-color);
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  justify-content: space-between;
}

.file-box {
  display: flex;
  gap: 1rem;
  flex-direction: row;
  align-items: center;
}

.file-data {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: space-between;
  align-items: center;
  height: 2rem;
  color: #00a4bd;
  font-weight: bold;
  font-size: 12px;
  text-decoration: none;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}

@media screen and (max-width: 992px) {
  .file-data {
    width: 58ch;
  }
}
@media screen and (max-width: 768px) {
  .file-data {
    width: 40ch;
  }
}

@media screen and (max-width: 576px) {
  .file-data {
    width: 22ch;
  }
}

@media screen and (max-width: 360px) {
  .file-data {
    width: 16ch;
  }
}

@media screen and (max-width: 320px) {
  .file-row {
    flex-direction: column;
  }
  .file-size {
    padding-bottom: 1rem;
  }
}
.form-check {
  display: flex;
  min-height: 1.5rem;
  padding-left: 1.5em;
  margin-bottom: 0.125rem;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
  gap: 1rem;
}

.form-check > label {
  color: var(--base-font);
  font-weight: bold;
  font-size: 12px;
  font-weight: 100;
}

.form-check-input {
  width: 1.2rem;
  height: 1.2rem;
}

.form-check .form-check-input {
  margin-left: -0.3rem;
}

.save-button {
  border-radius: 2px;
  margin-left: 1.4rem;
}

.file-size {
  font-weight: bold;
  font-size: 12px;
  padding-right: 1rem !important;
}

.row > * {
  flex-shrink: 0;
  width: unset;
  max-width: unset;
  padding-right: calc(var(--bs-gutter-x) * 0.1);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-top: var(--bs-gutter-y);
}
